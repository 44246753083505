<template>
  <v-container fluid v-if="active">
    <div class="mx-auto text-center d-block mt-8 mb-10">
      <p v-if="reportCreationStatus === 0" color="orange">
        {{ $t("preparing") }}
      </p>
      <v-progress-circular
        v-if="reportCreationStatus === 1"
        size="50"
        color="amber"
        indeterminate
      >
        {{ $t("creating") }}
      </v-progress-circular>
      <p v-if="reportCreationStatus !== null">
        {{ $t(reportCreationStatus) }}
      </p>
    </div>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsReportGeneration",
  components: {},
  data() {
    return {
      value: null,
      sharedData: null,
      reportCreationJobId: null,
      reportCreationStatus: null,
      active: false,
    };
  },
  computed: {
    optionalInputValues() {
      return [
        "dailyTime",
        "effectiveWork",
        "calendarType",
        "calendar",
        "normalWork",
      ];
    },
  },
  methods: {
    init(value, sharedData) {
      console.log("GsReportGeneration: init", value);
      this.value = value;
      this.sharedData = sharedData;
      this.active = true;
      this.reportCreationJobId = null;
      this.reportCreationStatus = null;
      this.$EventBus.$on("report_created_result", this.onReportCreatedResult);
      this.encloseOptionalInputValues();
      this.sendJob();
    },
    stop() {
      console.log("GsReportGeneration: stop");
      this.$EventBus.$off("report_created_result", this.onReportCreatedResult);
      this.active = false;
      return this.cloneObj(this.value);
    },
    async sendJob() {
      // quick report or scheduling?
      if (this.sharedData.needScheduling) {
        await this.postTask();
      } else {
        await this.postJob();
      }
    },
    async postJob() {
      try {
        this.reportCreationJobId = null;
        this.reportCreationStatus = null;

        const url = `${process.env.VUE_APP_BFF_ORIGIN}/jobs`;
        const method = "POST";
        const data = {
          templateId: this.value.referenceParameters.templateId,
          inputValues: this.value.referenceParameters.inputValues,
          notificationValues: this.value.referenceParameters.notificationValues,
        };

        this.reportCreationStatus = "report_created_active";

        const response = await callAPI({ url, method, data });

        this.reportCreationJobId = response.data.jobId;
        console.log("EventBus emit checkJob");
        this.$EventBus.$emit("checkJob", this.reportCreationJobId, 3000);
      } catch (error) {
        this.reportCreationStatus = "report_created_failed";
        this.errorSnackbar(error);
      }
    },
    async postTask() {
      try {
        this.reportCreationJobId = null;
        this.reportCreationStatus = null;

        // let url = `${process.env.VUE_APP_BFF_ORIGIN}/tasks`;
        let url = `/tasks`;
        let method = "POST";

        if (this.sharedData.edit) {
          url += `/${this.value.taskId}`;
          method = "PATCH";
        }

        console.log("postTask this.value:", this.value);
        const data = {
          name: this.value.name,
          description: this.value.description,

          minute: this.value.minute,
          hour: this.value.hour,
          month: this.value.month,
          day: `${this.value.day}`,
          dayType: this.value.dayType,

          timezone: this.value.timezone,

          reference: `${process.env.VUE_APP_REPORT_MANAGER_API}/jobs`,
          referenceParameters: this.value.referenceParameters,

          startTime: this.value.startTime,
          endTime: this.value.endTime,
          enabled: this.value.enabled,
        };

        // const data = {
        //   name: this.value.schedulingName,
        //   description: this.value.schedulingDescription,

        //   minute: this.value.schedulingTimeData.minute,
        //   hour: this.value.schedulingTimeData.hour,
        //   month: this.value.schedulingTimeData.month,
        //   day: this.value.schedulingTimeData.day,
        //   dayType: this.value.schedulingTimeData.dayType,

        //   timezone: this.value.selectedTimezone,

        //   reference: `${process.env.VUE_APP_BFF_ORIGIN}/jobs`,
        //   referenceParameters: {
        //     templateId: this.value.selectedTemplate.templateId,
        //     inputValues,
        //     notificationValues: this.value.notificationValues
        //   },
        //   startTime: dayjs(this.value.schedulingStartTime).toISOString(),
        //   endTime:
        //     (this.value.schedulingEndTime &&
        //       dayjs(this.value.schedulingEndTime).toISOString()) ||
        //     null,
        //   enabled: true
        // };

        // console.log(data)

        // const response = await callAPI({ url, method, data });

        try {
          const response = await callBffAPI({ url, method, data });
          this.reportCreationStatus = "report_scheduled_success";
        } catch (error) {
          this.reportCreationStatus = "report_created_failed";
          this.errorSnackbar(error);
        }
        // await this.sleep(2000);
      } catch (error) {
        this.reportCreationStatus = "report_created_failed";
        this.errorSnackbar(error);
      }
    },
    onReportCreatedResult(jobId, status) {
      console.log(jobId, status);
      this.reportCreationJobId = jobId;
      this.reportCreationStatus = status;
    },
    validate() {
      let error = null;
      return error;
    },
    encloseOptionalInputValues() {
      if (this.value?.referenceParameters?.inputValues) {
        const additionalValues = {};
        this.optionalInputValues.forEach((item) => {
          if (item in this.value.referenceParameters.inputValues) {
            additionalValues[item] =
              this.value.referenceParameters.inputValues[item];
            delete this.value.referenceParameters.inputValues[item];
          }
        });
        if (Object.keys(additionalValues).length) {
          this.value.referenceParameters.inputValues["additionalValues"] =
            additionalValues;
        }
      }
    },
  },
};
</script>
